<template>
    <svg :height="radius * 2" :width="radius * 2">
        <circle
            class=""
            stroke="white"
            :stroke-dasharray="circumference + ' ' + circumference"
            :style="{ strokeDashoffset: strokeDashoffset }"
            :stroke-width="stroke"
            fill="transparent"
            :r="normalizedRadius"
            :cx="radius"
            :cy="radius"
            stroke-linecap="round"
        />
        <text :x="radius" :y="radius" text-anchor="middle" dominant-baseline="central" fill="white" class="font-bold text-4xl text-white text-center">{{ text }}</text>
    </svg>
</template>

<script>
export default {
    name: "ProgressRing",
    props: ["radius", "progress", "stroke", "text"],
    computed: {
        normalizedRadius() {
            return this.radius - this.stroke * 2;
        },
        circumference() {
            return this.normalizedRadius * 2 * Math.PI;
        },
        strokeDashoffset() {
            return this.circumference - (this.progress / 100) * this.circumference;
        },
    },
};
</script>

<style lang="scss" scoped></style>
