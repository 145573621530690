<template>
    <section class="flex flex-col w-full justify-start items-center py-20 relative overflow-x-hidden overflow-y-hidden tools">
        <div class="triangle-left absolute top-0 -translate-y-[40%] h-[20rem] lg:-translate-y-[49%] left-0 -z-50 lg:h-[35rem]"></div>
        <div class="triangle-right absolute top-0 -translate-y-[40%] h-[20rem] lg:-translate-y-[49%] right-0 -z-50 lg:h-[35rem]"></div>
        <div class="absolute rounded-full border-4 border-[#F5F8FF] w-[45rem] h-[45rem] left-0 -translate-x-2/3 top-1/4 bg-transparent -z-50"></div>
        <div class="absolute rounded-full border-4 border-[#F5F8FF] w-[50rem] h-[50rem] right-0 translate-x-1/2 bottom-0 -translate-y-[5rem] bg-transparent -z-50"></div>
        <div class="absolute rounded-full border-4 border-[#F5F8FF] w-[38rem] h-[38rem] right-0 translate-x-2/3 bottom-0 -translate-y-[11rem] bg-transparent -z-50"></div>
        <!-- <div class="absolute rounded-full border-2 border-[#FF725E] w-[60rem] h-[60rem] left-0 -bottom-20 -translate-x-3/4 translate-y-1/2 top-1/4 bg-transparent -z-50 hidden lg:block"></div> -->
        <div class="flex flex-col items-center justify-start w-full">
            <h2 class="w-full text-center text-base lg:text-lg font-semibold text-[#5D5FEF] mb-4">SKILLS ASSESSMENT APP</h2>
            <h1 class="w-full text-center text-4xl lg:text-5xl font-bold text-slate-700 mb-6 lg:mb-12">
                All The Tools That You
                <span class="relative overflow-visible whitespace-nowrap text-white z-10">
                    <div class="rotate-[3deg] bg-[#78C6E4] absolute w-full h-full top-0 left-0 -z-10 opacity-80"></div>
                    Need.
                </span>
            </h1>
            <p class="w-full text-center text-base font-light text-slate-500">Skill Assessment and Talent resourcing in a single solution.</p>
        </div>
        <div class="w-full flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2 flex items-center flex-col justify-center py-8 px-10 lg:px-0">
                <h2 class="w-full lg:w-1/2 text-left text-sm lg:text-base font-semibold text-[#5D5FEF] mb-4">POWERFUL</h2>
                <h1 class="w-full lg:w-1/2 text-left text-xl lg:text-3xl font-bold text-slate-700 mb-6 lg:mb-12">All the tools you can imagine</h1>
                <p class="w-full lg:w-1/2 text-justify lg:text-left text-sm font-light text-slate-500 mb-8">
                    Unlock the full potential of your workforce with our cutting-edge talent assessment tools. From comprehensive evaluations to data-driven insights, we empower you to make informed
                    decisions and build high-performing teams. Discover the future of talent management today!
                </p>
                <button class="flex flex-row items-center justify-start gap-6 mr-auto lg:mr-0 font-bold text-base lg:w-1/2 text-[#5D5FEF]">
                    Check the tools<font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
            <div class="relative w-full lg:w-1/2 flex items-center flex-col justify-center py-8 px-10 lg:px-0">
                <!-- <ToolIllustration class="illustrationOne" /> -->
                <!-- <img loading="lazy"  decoding="async" src="../assets/landing-page-logos/Time Efficient.svg" alt="" width="1440" /> -->
                <!-- <img loading="lazy"  decoding="async" src="../assets/Images/bg_cost.png" alt="widget"  class="cost_image_bg absolute top-0 right-0 p-0 rounded-md w-full h-full z-0" /> -->
                <img loading="lazy" decoding="async" src="../../assets/Images/man-cost.svg" alt="widget2" class="cost_image absolute -right-20 rounded-md w-[120%] h-[120%] overflow-visible" />
                <img loading="lazy" decoding="async" src="../../assets/Images/time_chart.svg" alt="widget" class="cost_image hover:scale-110 absolute bottom-5 left-1 w-[24rem] h-64 rounded-md" />
                <img loading="lazy" decoding="async" src="../../assets/Images/time_chart2.svg" alt="widget2" class="cost_image hover:scale-110 absolute top-2 left-3 rounded-md w-64 h-72" />
            </div>
        </div>
        <div class="w-full flex flex-col-reverse lg:flex-row">
            <div class="w-full lg:w-1/2 flex items-center flex-col justify-center py-8 px-10 lg:px-0"><ToolIllustration2 class="illustrationTwo" /></div>
            <div class="w-full lg:w-1/2 flex items-center flex-col justify-center py-8 px-10 lg:px-0">
                <h2 class="w-full lg:w-1/2 text-left text-sm lg:text-base font-semibold text-[#5D5FEF] mb-4">Compare talent</h2>
                <h1 class="w-full lg:w-1/2 text-left text-xl lg:text-3xl font-bold text-slate-700 mb-6 lg:mb-12">Get all the tools your team needs</h1>
                <p class="w-full lg:w-1/2 text-justify lg:text-left text-sm font-light text-slate-500 mb-8">
                    Gain a competitive edge in talent acquisition with our advanced assessment tools. From evaluating aptitude and personality to analyzing skills, our platform equips you to make
                    data-driven decisions. Uncover high-potential candidates and build exceptional teams!
                </p>
                <button class="flex flex-row items-center justify-start gap-6 mr-auto lg:mr-0 font-bold text-base lg:w-1/2 text-[#5D5FEF]">
                    Check the tools<font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
        </div>
        <div class="w-full flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2 flex items-center flex-col justify-center py-8 px-10 lg:px-0">
                <h2 class="w-full lg:w-1/2 text-left text-sm lg:text-base font-semibold text-[#5D5FEF] mb-4">POWERFUL</h2>
                <h1 class="w-full lg:w-1/2 text-left text-xl lg:text-3xl font-bold text-slate-700 mb-6 lg:mb-12">All the tools you can imagine</h1>
                <p class="w-full lg:w-1/2 text-justify lg:text-left text-sm font-light text-slate-500 mb-8">
                    Unlock the full potential of your workforce with our cutting-edge talent assessment tools. From comprehensive evaluations to data-driven insights, we empower you to make informed
                    decisions and build high-performing teams. Discover the future of talent management today!
                </p>
                <button class="flex flex-row items-center justify-start gap-6 mr-auto lg:mr-0 font-bold text-base lg:w-1/2 text-[#5D5FEF]">
                    Check the tools<font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
            </div>
            <div class="w-full lg:w-1/2 flex items-center flex-col justify-center py-8 px-10 lg:px-0 relative">
                <!-- <ToolIllustration class="illustrationThree" /> -->
                <img
                    loading="lazy"
                    decoding="async"
                    src="../../assets/Images/woman_cost.svg"
                    alt="widget2"
                    class="cost2_image absolute top-0 -right-20 rounded-md w-[120%] h-[120%] overflow-visible"
                />
                <img loading="lazy" decoding="async" src="../../assets/Images/cost_widget.svg" alt="widget" class="cost2_image hover:scale-110 absolute top-2 right-48 w-56 h-44 rounded-md" />
                <img loading="lazy" decoding="async" src="../../assets/Images/cost_chart.svg" alt="widget2" class="cost2_image hover:scale-110 absolute top-16 left-10 rounded-md w-96 h-72" />
            </div>
        </div>
    </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
// import ToolIllustration from "../LandingPage/ToolIllustration.vue";
import ToolIllustration2 from "../LandingPage/ToolIllustration2.vue";
export default {
    name: "ToolSection",
    components: { /* ToolIllustration, */ ToolIllustration2 },
    mounted() {
        gsap.from(".illustrationOne", {
            x: 1000,
            duration: 2,
            ease: "power1.out",
            scrollTrigger: {
                trigger: ".tools",
                start: "top 20%",
                end: "30% top",
                toggleActions: "play none restart reset",
            },
        });
        gsap.from(".illustrationTwo", {
            scale: 0,
            duration: 2,
            ease: "power1.out",
            scrollTrigger: {
                trigger: ".tools",
                start: "35% 20%",
                end: "60% top",
                toggleActions: "play none restart reset",
            },
        });
        gsap.from(".illustrationThree", {
            x: 1000,
            duration: 2,
            ease: "power1.out",
            scrollTrigger: {
                trigger: ".tools",
                start: "60% 20%",
                end: "80% top",
                toggleActions: "play none restart reset",
            },
        });
    },
};
</script>

<style lang="scss" scoped>
/* HTML: <div class="triangle"></div> */
/* HTML: <div class="triangle"></div> */
.triangle-left {
    --r: 25px; /* border radius */

    width: 50%;
    // aspect-ratio: 1/2;
    -webkit-mask: linear-gradient(90deg, #0000 calc(var(--r) / sqrt(2)), #000 0), radial-gradient(var(--r) at calc(var(--r) * sqrt(2)) 50%, #000 98%, #0000 101%);
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    background: #f5f8ff;
}
/* HTML: <div class="triangle"></div> */
.triangle-right {
    --r: 25px; /* border radius */

    width: 50%;
    // aspect-ratio: 1/2;
    -webkit-mask: linear-gradient(-90deg, #0000 calc(var(--r) / sqrt(2)), #000 0), radial-gradient(var(--r) at calc(100% - var(--r) * sqrt(2)) 50%, #000 98%, #0000 101%);
    clip-path: polygon(100% 50%, 0 100%, 0 0);
    background: #f5f8ff;
}
</style>
