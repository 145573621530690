<template>
    <div class="line">
        <div>
            <span class="big-text"> {{ $t("Total Tasks Done") }} </span>
            <span class="little-text"> {{ $t("Tasks Completed in last 7 Days") }} </span>
        </div>
        <LineChart :graphData="this.graphData" :chartWidth="600" :chartHeight="300" />
        <!-- <svg class="chart-svg-line-task-done"></svg> -->
    </div>
</template>

<script>
// import * as d3 from "d3";
import LineChart from "../charts/Graph.vue";
export default {
    name: "taskDone",
    components: { LineChart },
    data() {
        return {
            selectedPeriod: "weekly",
            graphData: [
                // Provide your dataset here
                { Date: new Date("2023-01-01"), Close: 20 },
                { Date: new Date("2023-01-02"), Close: 60 },
                { Date: new Date("2023-01-03"), Close: 30 },
                { Date: new Date("2023-01-04"), Close: 50 },
                { Date: new Date("2023-01-05"), Close: 20 },
                { Date: new Date("2023-01-06"), Close: 60 },
                { Date: new Date("2023-01-07"), Close: 30 },
                { Date: new Date("2023-01-08"), Close: 50 },
            ],
        };
    },
    // mounted() {
    //   const containerWidth = document.querySelector(".chart-svg-line-task-done").clientWidth;
    //   const containerHeight = document.querySelector(".chart-svg-line-task-done").clientHeight;
    //   const margin = { top: 20, right: 20, bottom: 40, left: 50 };
    //   const width = containerWidth - margin.left - margin.right;
    //   const height = containerHeight - margin.top - margin.bottom;

    //   const svg = d3.select(".chart-svg-line-task-done")
    //     .attr("width", containerWidth)
    //     .attr("height", containerHeight);

    //   const g = svg.append("g")
    //     .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //   const parseTime = d3.timeParse("%d-%b-%y");

    //   const yExtent = d3.extent(this.data, function(d) {
    //     return d.amount;
    //   });

    //   const x = d3.scaleTime()
    //     .domain(d3.extent(this.data, function(d) {
    //       return parseTime(d.date);
    //     }))
    //     .range([0, width]);

    //   const y = d3.scaleLinear()
    //     .domain(yExtent)
    //     .range([height, 0]);

    //   const line = d3.line()
    //     .x(function(d) {
    //       return x(parseTime(d.date));
    //     })
    //     .y(function(d) {
    //       return y(d.amount);
    //     }).curve(d3.curveCatmullRom);

    //     g.append("g")
    //       .attr("transform", "translate(0," + height + ")")
    //       .call(d3.axisBottom(x).ticks(7).tickFormat(d3.timeFormat("%d")).tickSizeOuter(0))
    //       .call((g) => g.select(".domain").remove()) // Remove the x-axis line
    //       .call((g) => g.selectAll(".tick line").remove())// remove ticks
    //       .call((g) => g.selectAll("text").attr("fill", "#929EAE")); // Set the fill color of x-axis labels to gray

    //     g.append("g")
    //       .call(d3.axisLeft(y.domain([0, yExtent[1]])).ticks(5).tickSizeOuter(0))
    //       .call((g) => g.select(".domain").remove()) // Remove the y-axis line
    //       .call((g) => g.selectAll(".tick line").remove())// remove ticks
    //       .call((g) =>
    //         g.selectAll("text").attr("fill", "#929EAE") // Set the fill color of y-axis labels to gray
    //       );

    //   g.append("path")
    //     .datum(this.data)
    //     .attr("fill", "none")
    //     .attr("stroke", "#2196f3")
    //     .attr("stroke-width", 1.5)
    //     .attr("d", line);

    // },
};
</script>

<style scoped>
.chart-svg-line-task-done {
    width: 100%;
    height: 100%;
}

.little-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #5c5f62;
}

.line {
    background: #fefeff;
    padding: 3%;
    border: 0.6px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
}

.line > div {
    display: grid;
}

.big-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #000000;
}
</style>
