<template>
    <section class="flex flex-col w-full justify-start items-center pb-10 Metrics">
        <div class="flex flex-col items-center justify-start gap-8 bg-gradient-to-br from-[#00AEF0] to-[#7B2AFF] py-20 lg:px-12 text-white bg-white">
            <h1 class="font-bold text-2xl w-full lg:w-2/3 text-center lg:text-4xl z-10">
                "GO PLATFORM has created a consistent and unbiased way to
                <span class="relative overflow-visible whitespace-nowrap">
                    <div class="-rotate-[3deg] bg-[#65D365] absolute w-full h-full top-0 left-0 -z-10 opacity-80"></div>
                    Measure</span
                >
                our workforce."
            </h1>
            <h3 class="font-medium text-sm w-full text-center lg:text-base">Fernando Lucini, Global Data Science Lead, Accenture Applied Intelligence.</h3>
            <h2 class="font-bold text-base w-full text-center lg:text-lg">On average, enterprise employees that use GO PLATFORM see:</h2>
            <div class="w-full max-w-[1000px] flex flex-col lg:flex-row items-center gap-6 justify-start lg:justify-between">
                <div class="w-72 h-72 flex justify-between flex-col items-center relative">
                    <ProgressRing :radius="120" :progress="turnover" :stroke="12" :text="`-${turnover}%`" />
                    <h1 class="font-semibold text-3xl text-white text-center w-full">Turnover</h1>
                </div>
                <div class="w-72 h-72 flex justify-between flex-col gap-6 items-center relative">
                    <ProgressRing :radius="120" :progress="growth * 10" :stroke="12" :text="`${growth}K`" />

                    <h1 class="font-semibold text-3xl text-white text-center w-full">Faster skill growth</h1>
                </div>
                <div class="w-72 h-72 flex justify-between flex-col items-center relative">
                    <ProgressRing :radius="120" :progress="position" :stroke="12" :text="`+${position}%`" />
                    <h1 class="font-semibold text-3xl text-white text-center w-full">Position fit</h1>
                </div>
            </div>
        </div>
        <div class="w-11/12 max-w-[1000px] border border-[#DEE1E6] rounded-lg flex lg:flex-row flex-col justify-start gap-8 lg:gap-0 lg:justify-between items-center lg:px-14 py-10 mt-8">
            <h1 class="w-full lg:w-1/3 font-semibold text-2xl lg:text-3xl text-slate-700 text-center lg:text-left">We are a proud 5 star business</h1>
            <div class="flex flex-col justify-center items-center">
                <img loading="lazy" decoding="async" src="../../assets/Images/Google.svg" alt="google" class="w-32 h-12 aspect-video" />
                <div class="flex flex-row justify-start items-center"><font-awesome-icon v-for="star in stars" :key="star" class="w-8 h-8 text-[#FFBC3F]" :icon="['fas', 'star']" /></div>
            </div>
            <div class="flex flex-col justify-center items-center">
                <img loading="lazy" decoding="async" src="../../assets/Images/ProductHunt.svg" alt="Product Hunt" class="w-36 h-14 aspect-video" />
                <div class="flex flex-row justify-start items-center"><font-awesome-icon v-for="star in stars" :key="star" class="w-8 h-8 text-[#FFBC3F]" :icon="['fas', 'star']" /></div>
            </div>
        </div>
    </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);
import ProgressRing from "./ProgressRing.vue";
export default {
    name: "MetricSection",
    components: { ProgressRing },
    data() {
        return {
            stars: 5,
            growth: 0,
            turnover: 0,
            position: 0,
            growthInterval: null,
            turnoverInterval: null,
            positionInterval: null,
        };
    },
    methods: {
        IncrementMetric() {
            // Clear any existing interval
            clearInterval(this.growthInterval);
            clearInterval(this.positionInterval);
            clearInterval(this.turnoverInterval);
            // Create a new interval
            this.growthInterval = setInterval(() => {
                // Increment the growth metric
                this.growth += 0.25;
                // Stop the interval when growth reaches 8
                if (this.growth >= 8) {
                    clearInterval(this.growthInterval);
                }
            }, 10);
            this.positionInterval = setInterval(() => {
                this.position++;
                if (this.position >= 97) {
                    clearInterval(this.positionInterval);
                }
            }, 5);
            this.turnoverInterval = setInterval(() => {
                this.turnover++;
                if (this.turnover >= 72) {
                    clearInterval(this.turnoverInterval);
                }
            }, 5);
        },
    },
    mounted() {
        ScrollTrigger.create({
            trigger: ".Metrics",
            start: "50% 80%",
            toggleActions: "play none restart reset",
            // markers: { startColor: "#ffe000", endColor: "#ff0efe", fontSize: "18px", fontWeight: "bold", indent: 20 },
            onEnter: this.IncrementMetric,
            onEnterBack: this.IncrementMetric, // Also trigger on re-entering the viewport
            onLeave: () => clearInterval(this.growthInterval), // Clear the interval when leaving the viewport
            onLeaveBack: () => clearInterval(this.growthInterval),
        });
    },
    beforeUnmount() {
        clearInterval(this.growthInterval); // Clear the interval when the component is unmounted
    },
};
</script>

<style lang="scss" scoped></style>
