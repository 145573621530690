<template>
    <div class="w-11/12 max-w-[800px] h-[] lg:h-[500px] max-h-[700px] grid grid-cols-4 grid-rows-[repeat(10,_minmax(0,_1fr))] rounded-s-2xl shadow-card overflow-hidden lg:mr-auto">
        <div class="w-full bg-white flex items-center flex-row justify-start gap-2 row-start-1 row-end-2 col-start-1 col-span-10 p-2 lg:p-4">
            <span class="w-2 h-2 rounded-full bg-[#E11D48]"></span><span class="w-2 h-2 rounded-full bg-[#FBBF24]"></span><span class="w-2 h-2 rounded-full bg-[#22C55E]"></span>
        </div>
        <div class="row-start-2 row-end-11 col-start-1 col-end-2 flex flex-col items-center justify-start gap-3 lg:gap-6 bg-[#BFDBFE] p-2 pt-4 lg:p-4 lg:pt-12">
            <span class="w-full rounded-full h-2 lg:h-5 bg-[#93C5FD] mb-4 lg:mb-8"></span>
            <div class="w-full p-0 flex flex-row items-center justify-start gap-3">
                <span class="w-2 h-2 lg:w-4 lg:h-4 border-4 border-[#93C5FD] rounded-full"></span><span class="bg-white rounded-full w-2/3 h-2"></span>
            </div>
            <div class="w-full p-0 flex flex-row items-center justify-start gap-3">
                <span class="w-2 h-2 lg:w-4 lg:h-4 border-4 border-[#60A5FA]"></span><span class="bg-white rounded-full w-1/2 h-2"></span>
            </div>
            <div class="w-full p-0 flex flex-row items-center justify-start gap-3">
                <span class="w-2 h-2 lg:w-4 lg:h-4 bg-[#93C5FD] triangle"></span><span class="bg-white rounded-full w-3/5 h-2"></span>
            </div>
        </div>
        <div class="bg-[#DBEAFE] row-start-2 row-end-11 col-start-2 col-end-5 flex flex-col justify-center items-center p-0">
            <div class="w-full h-full lg:h-1/2 relative p-2 pt-4 lg:p-4 lg:pt-12">
                <span class="rounded-full absolute left-8 bg-[#BFDBFE] w-16 h-3 lg:w-28 top-4 lg:top-12"></span>
                <span class="rounded-full absolute left-8 bg-white w-10 h-2 lg:w-16 top-10 lg:top-28"></span>
                <span class="rounded-full absolute left-8 lg:left-28 bg-white w-20 h-2 lg:w-40 top-14 lg:top-28"></span>
                <span class="rounded-full absolute left-32 lg:left-8 bg-white w-16 h-2 lg:w-32 top-14 lg:top-36"></span>
                <div
                    class="h-2/5 w-11/12 lg:w-40 lg:h-5/6 bg-white rounded-xl absolute left-1/2 lg:left-auto lg:right-1 -translate-x-1/2 lg:translate-x-0 bottom-1 lg:bottom-0 flex flex-row lg:flex-col justify-between items-center overflow-hidden"
                >
                    <div class="w-16 h-16 border-4 border-[#BFDBFE] bg-transparent rotate-45 relative left-0 -translate-x-2 -top-2 lg:right-0 lg:translate-x-14 lg:top-4 bigSquare"></div>
                    <div class="w-24 h-24 bg-[#FACC15] relative lg:left-0 translate-x-1 lg:-translate-x-16 bottom-0 translate-y-6 rounded-full yellowCircle"></div>
                </div>
            </div>
            <div class="w-full h-0 hidden lg:block lg:h-1/2 relative">
                <span class="rounded-full absolute left-8 bg-[#BFDBFE] h-3 lg:w-28 top-4 lg:top-12"></span>
                <span class="rounded-full absolute left-40 bg-[#BFDBFE] h-3 lg:w-20 top-4 lg:top-12"></span>
                <span class="rounded-full absolute left-8 bg-white h-2 lg:w-20 top-20"></span>
                <span class="rounded-full absolute left-32 bg-white h-2 lg:w-16 top-20"></span>
                <span class="rounded-full absolute left-8 bg-white h-2 lg:w-40 top-24"></span>
                <div class="h-3/5 w-1/3 lg:w-40 lg:h-5/6 bg-white rounded-xl absolute right-1 lg:bottom-0 flex flex-row lg:flex-col justify-between items-center overflow-hidden">
                    <div class="w-14 h-14 bg-[#BFDBFE] rounded-full relative right-4 top-6 smallCircle"></div>
                    <div class="w-28 h-28 border-4 border-[#FACC15] bg-transparent relative left-0 translate-x-10 bottom-0 translate-y-4 rounded-full bigCircle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(MotionPathPlugin);
export default {
    name: "ToolIllustration",
    mounted() {
        gsap.to(".yellowCircle", {
            motionPath: {
                path: [
                    { x: 0, y: 0 },
                    { x: 5, y: -5 },
                    { x: -4, y: -5 },
                    { x: 0, y: 0 },
                ],
            },
            duration: 5,
            ease: "ease",
            repeat: -1,
        });
        gsap.to(".bigSquare", {
            motionPath: {
                path: [
                    { x: -10, y: -10 },
                    { x: -5, y: 0 },
                    { x: -5, y: 8 },
                    { x: 0, y: 0 },
                ],
            },
            duration: 8,
            ease: "ease",
            repeat: -1,
            yoyo: true,
        });
        gsap.to(".bigCircle", {
            motionPath: {
                path: [
                    { x: -10, y: 0 },
                    { x: -5, y: 7 },
                    { x: 0, y: 0 },
                ],
            },
            duration: 8,
            ease: "ease",
            repeat: -1,
        });
        gsap.to(".smallCircle", {
            motionPath: {
                path: [
                    { x: -10, y: -10 },
                    { x: -5, y: 0 },
                    { x: -5, y: 8 },
                    { x: 0, y: 0 },
                ],
            },
            duration: 8,
            ease: "ease",
            repeat: -1,
        });
    },
};
</script>

<style lang="scss" scoped>
/* HTML: <div class="triangle"></div> */
.triangle {
    // width: 180px;
    aspect-ratio: 1 / cos(30deg);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
}
.row-end-11 {
    grid-row-end: 11;
}
</style>
