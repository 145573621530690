<template>
    <div class="w-full h-full hidden lg:grid grid-cols-5 gap-3 overflow-hidden">
        <div class="flex flex-row justify-between items-center row-start-1 col-start-1 col-end-6 gap-4">
            <div class="widget w-1/4 px-3 py-2 bg-white shadow-card flex flex-col justify-center items-center hover:scale-110 duration-500 ease-in-out">
                <h1 class="widget-title text-slate-600 font-bold text-sm text-left w-full">{{ $t("Completion") }}</h1>
                <div class="flex flex-row items-center justify-between w-full">
                    <span class="widget-info text-base font-bold">77%</span>
                    <img loading="lazy" decoding="async" src="../../assets/Images/icons/bar-graph.svg" alt="bar-graph" class="w-6 h-6" />
                </div>
            </div>
            <div class="widget w-1/4 px-3 py-2 bg-white shadow-card flex flex-col justify-center items-center hover:scale-110 duration-500 ease-in-out">
                <h1 class="widget-title text-slate-600 font-bold text-sm text-left w-full">{{ $t("Applicants") }}</h1>
                <div class="flex flex-row items-center justify-between w-full">
                    <span class="widget-info text-base font-bold">34</span>
                    <img loading="lazy" decoding="async" src="../../assets/Images/icons/bar-chart-icon.svg" alt="bar-chart-icon" class="w-6 h-6" />
                </div>
            </div>
            <div class="widget w-1/4 px-3 py-2 bg-gradient-to-r from-[#868cff] to-[#2196f3] text-white shadow-card flex flex-col justify-center items-center hover:scale-110 duration-500 ease-in-out">
                <h1 class="widget-title font-bold text-sm text-left w-full">{{ $t("Assessements") }}</h1>
                <div class="flex flex-row items-center justify-between w-full">
                    <span class="widget-info text-base font-bold">5</span>
                    <img loading="lazy" decoding="async" src="../../assets/Images/icons/widget-chart.svg" alt="widget-chart" class="w-10 h-6" />
                </div>
            </div>
            <div class="widget w-1/4 px-3 py-2 bg-gradient-to-r from-[#868cff] to-[#2196f3] text-white shadow-card flex flex-col justify-center items-center hover:scale-110 duration-500 ease-in-out">
                <h1 class="widget-title font-bold text-sm text-left w-full">{{ $t("Success Rate") }}</h1>
                <div class="flex flex-row items-center justify-between w-full">
                    <span class="widget-info text-base font-bold">93%</span>
                    <img loading="lazy" decoding="async" src="../../assets/Images/icons/Chart_100.svg" alt="bar-chart-icon" class="w-10 h-6" />
                </div>
            </div>
        </div>
        <div class="row-start-2 col-start-1 col-end-3 flex flex-col px-4 py-8 items-center justify-start bg-white shadow-card rounded-xl hover:scale-110 duration-500 ease-in-out">
            <h1 class="font-bold text-sm w-full text-left mb-4">Analogy</h1>
            <p class="font-light text-[10px] leading-4 text-left w-full mb-7">
                This assessment is designed to evaluate candidates' cognitive abilities related to recognizing and understanding relationships between concepts. Candidates will encounter a series
                of...
            </p>
            <div class="flex flex-row w-full justify-start gap-4 text-sm font-light mt-auto mb-2"><font-awesome-icon :icon="['far', 'clock']" /> 11 minutes</div>
            <div class="flex flex-row justify-between items-center w-full">
                <span class="w-16 h-8 rounded-lg flex justify-center items-center border border-NeonBlue text-NeonBlue"><font-awesome-icon :icon="['far', 'eye']" /></span>
                <span class="w-16 h-8 rounded-lg flex justify-center items-center text-white bg-NeonBlue text-xs font-bold">Details</span>
            </div>
        </div>
        <div class="row-start-2 col-start-3 col-end-6 w-full h-full px-0 py-8 flex flex-col items-center justify-start bg-white shadow-card rounded-xl hover:scale-110 duration-500 ease-in-out">
            <h1 class="font-bold text-sm w-full text-left mb-4 pl-4">Talents</h1>
            <div
                class="relative w-full flex flex-row justify-between items-end h-48 px-4 pl-8 before:absolute before:content-('') before:w-px before:h-full before:bg-gray-400 before:top-0 before:left-5 after:absolute after:content-('') after:w-full after:h-px after:border after:border-dashed after:border-gray-400 after:left-0 after:bottom-40"
            >
                <span class="w-10 h-1/2 rounded bg-NeonBlue"></span>
                <span class="w-10 h-3/4 rounded bg-NeonBlue"></span>
                <span class="w-10 h-5/6 rounded bg-NeonBlue"></span>
                <span class="w-10 h-1/3 rounded bg-NeonBlue"></span>
                <span class="w-10 h-1/4 rounded bg-NeonBlue"></span>
                <span class="w-10 h-5/6 rounded bg-NeonBlue"></span>

                <div class="absolute h-full left-0 top-0 flex flex-col justify-between items-center">
                    <h3 class="text-xs font-light text-gray-400 text-left">100</h3>
                    <h3 class="text-xs font-light text-gray-400 text-left">80</h3>
                    <h3 class="text-xs font-light text-gray-400 text-left">60</h3>
                    <h3 class="text-xs font-light text-gray-400 text-left">40</h3>
                    <h3 class="text-xs font-light text-gray-400 text-left">20</h3>
                    <h3 class="text-xs font-light text-gray-400 text-left">0</h3>
                </div>
            </div>
        </div>
        <div class="col-start-1 col-end-6 row-start-3 flex flex-col justify-center items-center p-5 bg-white shadow-card rounded-xl hover:scale-110 duration-500 ease-in-out gap-3">
            <div class="w-full flex flex-row justify-between items-center">
                <h1 class="font-bold text-base text-slate-700 text-left">Invite Co-worker</h1>
                <font-awesome-icon :icon="['fas', 'xmark']" class="border border-gray-400 w-3 h-3 rounded-full text-slate-700 font-bold text-xs p-1" />
            </div>
            <div class="w-full flex flex-row justify-between items-center gap-2">
                <div class="px-4 w-5/6 border border-gray-400 rounded flex flex-row justify-start items-center gap-4 h-12">
                    <font-awesome-icon :icon="['fas', 'envelope']" class="text-NeonBlue w-5 h-5" />
                    <span class="text-gray-400 font-bold text-xs">Email</span>
                </div>
                <span class="w-1/6 bg-NeonBlue text-white text-xs flex justify-center items-center font-bold rounded h-full">Invite</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeroIllustration",
};
</script>

<style lang="scss" scoped>
.widget,
.widget-2 {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.widget-title {
    display: block;
    margin-bottom: 8px;
    text-align: left;
}

.widget-info {
    display: block;
    text-align: left;
}
</style>
