<template>
    <section class="cards flex flex-col w-full justify-start items-center py-20 text-white bg-gradient-to-r from-[#3DB5E6] to-[#312F92]">
        <h1 class="font-bold text-3xl lg:text-5xl text-center w-11/12 lg:w-1/2 my-6">
            How GO PLATFORM
            <span class="relative overflow-visible whitespace-nowrap text-[#3968FA] z-10">
                <div class="rotate-[3deg] bg-white absolute w-full h-full top-0 left-0 -z-10 opacity-80"></div>
                Helps
            </span>
        </h1>
        <p class="w-10/12 lg:w-1/2 text-justify lg:text-center font-light text-base my-6">
            Give your workforce personalized learning plans and your leadership the visibility and insights needed for better talent decisions–all seamlessly integrated into your technology ecosystem.
        </p>
        <div class="max-w-[1000px] w-11/12 lg:w-full flex flex-col lg:flex-row justify-between my-6 items-center gap-5 lg:gap-0">
            <InfoCard v-for="card in cards" :key="card.title.trim()" :title="card.title" :text="card.text" :link="card.link" class="card">
                <font-awesome-icon :icon="card.icon" class="text-[#3db5e6] mr-auto w-12 h-12" />
            </InfoCard>
        </div>
        <router-link to="/Tour" class="w-fit px-12 h-fit py-4 text-sm font-bold rounded bg-gradient-to-r from-[#3DB5E6] to-[#5D5FEF] my-6">More About GO Platform</router-link>
    </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import InfoCard from "../LandingPage/InfoCard.vue";
export default {
    name: "HowSection",
    components: { InfoCard },
    data() {
        return {
            cards: [
                {
                    title: "Who we serve",
                    icon: "fa-solid fa-trophy",
                    link: "/#",
                    text: "Enterprise leaders in Data, AI, Technology, and L&D use GO Platform to gain critical skills intelligence, make better informed talent decisions, and prepare their workforce for the future.",
                },
                {
                    title: "Our skill domains",
                    icon: "fa-solid fa-seedling",
                    link: "/#",
                    text: "Currently spanning technologies like data science, AI, software, machine learning, cloud, and more, our domains and measurement evolve with the pace of the industry.",
                },
                {
                    title: "How we integrate",
                    icon: "fa-solid fa-sun",
                    link: "/#",
                    text: "GO Platform is designed to complement and strengthen the tools and content stack your enterprise uses today. We integrate seamlessly and help you make the most of your investments.",
                },
            ],
        };
    },
    mounted() {
        gsap.from(".card", {
            opacity: 0,
            duration: 3,
            stagger: 0.5,
            scrollTrigger: {
                trigger: ".cards",
                start: "top 20%",
                toggleActions: "play none restart reset",
            },
        });
    },
};
</script>

<style lang="scss" scoped></style>
