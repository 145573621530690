<template>
    <div class="lg:ml-32 relative w-96 h-96 flex justify-center items-center">
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-[#C084FC] rounded-full w-full h-full"></div>
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-[#C084FC] rounded-full w-4/5 h-4/5"></div>
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-[#C084FC] rounded-full w-3/5 h-3/5"></div>
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-[#C084FC] rounded-full w-2/5 h-2/5"></div>
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-[#C084FC] rounded-full w-1/5 h-1/5"></div>

        <span class="w-px h-48 bg-[#C084FC] top-0 left-1/2 absolute"></span>
        <span class="w-px h-48 bg-[#C084FC] left-1/2 -translate-x-1/2 -translate-y-1/2 absolute origin-bottom-left rotate-[72deg]"></span>
        <span class="w-px h-48 bg-[#C084FC] left-1/2 -translate-x-1/2 -translate-y-1/2 absolute origin-bottom-left rotate-[144deg]"></span>
        <span class="w-px h-48 bg-[#C084FC] left-1/2 -translate-x-1/2 -translate-y-1/2 absolute origin-bottom-left rotate-[216deg]"></span>
        <span class="w-px h-48 bg-[#C084FC] left-1/2 -translate-x-1/2 -translate-y-1/2 absolute origin-bottom-left rotate-[288deg]"></span>

        <div class="bg-[#D8B4FE] fuschia absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 opacity-90 -z-10"></div>
        <div class="bg-[#3789FF] fuschia2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2/3 h-2/3 opacity-50 -rotate-12 -z-10"></div>

        <div class="image absolute w-12 h-12 bg-white shadow-card rounded-full top-16 right-9">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail12.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-10 h-10 bg-white shadow-card rounded-full top-20 left-20">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail11.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <!-- <div class="absolute w-10 h-10 bg-white shadow-card rounded-full top-2 left-10">
            <img loading="lazy"  decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail13.svg" alt="tool" class="w-full h-full aspect-square object-none">
        </div> -->
        <div class="image absolute w-6 h-6 bg-white shadow-card rounded-full top-32 left-40">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail15.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-12 h-12 bg-white shadow-card rounded-full top-32 left-8">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-8 h-8 bg-white shadow-card rounded-full bottom-16 right-4">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail1.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-10 h-10 bg-white shadow-card rounded-full top-2 right-14">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail2.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-8 h-8 bg-white shadow-card rounded-full bottom-32 left-20">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail3.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-10 h-10 bg-white shadow-card rounded-full bottom-2 right-16">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail4.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-10 h-10 bg-white shadow-card rounded-full bottom-2 right20">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail5.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-7 h-7 bg-white shadow-card rounded-full top-32 right-10">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail6.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-8 h-8 bg-white shadow-card rounded-full top-4 left-36">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail8.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-12 h-12 bg-white shadow-card rounded-full bottom-24 left-2">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail7.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-10 h-10 bg-white shadow-card rounded-full bottom-32 left-1/2">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail9.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
        <div class="image absolute w-10 h-10 bg-white shadow-card rounded-full top-40 left-56">
            <img loading="lazy" decoding="async" src="../../assets/Images/ToolsIllustration/App-thumbnail10.svg" alt="tool" class="w-full h-full aspect-square object-none" />
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
export default {
    name: "ToolIllustration2",
    mounted() {
        gsap.from(".image", {
            duration: 1,
            stagger: 0.5,
            scale: 0,
            repeat: -1,
            repeatDelay: 2,
        });
    },
};
</script>

<style lang="scss" scoped>
.fuschia {
    clip-path: polygon(100% 34%, 84.45% 97.55%, 12.55% 100.39%, 0.55% 34.61%, 50.45% 2.45%);
}
.fuschia2 {
    clip-path: polygon(0 23%, 73% 0, 100% 64%, 71% 100%);
}
</style>
