<template>
    <footer class="w-full flex flex-col gap-5 justify-center items-center pt-5">
        <div class="w-full flex flex-col-reverse lg:flex-row justify-center gap-8 lg:gap-0 items-center lg:items-start">
            <div class="flex flex-col items-center justify-start w-3/4 lg:w-1/5 gap-5">
                <img loading="lazy" decoding="async" src="../../assets/Images/footer_logo.svg" alt="Logo" class="w-full mr-auto" />
                <p class="text-slate-500 text-base font-light w-full text-left pr-10">Equal Access to opportunities is a basic right.</p>
                <div class="w-full flex flex-row justify-start gap-3 items-center">
                    <a href=""><font-awesome-icon class="text-[#3734A9] w-6 h-6" :icon="['fab', 'linkedin-in']" /></a>
                    <a href=""><font-awesome-icon class="text-[#3734A9] w-6 h-6" :icon="['fab', 'facebook-messenger']" /></a>
                    <a href=""><font-awesome-icon class="text-[#3734A9] w-6 h-6" :icon="['fab', 'x-twitter']" /></a>
                    <a href=""><font-awesome-icon class="text-[#3734A9] w-6 h-6" :icon="['fab', 'facebook-f']" /></a>
                </div>
            </div>
            <div class="flex flex-col justify-start items-center gap-2 w-3/4 lg:w-1/5">
                <h2 class="font-bold text-lg mb-3 text-slate-700 w-full text-left">Company</h2>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("About Us") }}</router-link>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Careers") }}</router-link>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Blogs") }}</router-link>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Pricing") }}</router-link>
            </div>
            <div class="flex flex-col justify-start items-center gap-2 w-3/4 lg:w-1/5">
                <h2 class="font-bold text-lg mb-3 text-slate-700 w-full text-left">Ressources</h2>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Templates") }}</router-link>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Tutorials") }}</router-link>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Free Ressources") }}</router-link>
                <router-link to="/" class="text-left text-slate-700 font-light w-full"> {{ $t("Contact templates") }}</router-link>
            </div>
            <div class="flex flex-col justify-start items-center gap-6 w-3/4 lg:w-1/5">
                <h2 class="font-bold text-lg text-slate-700 w-full text-left">Join Our Newsletter</h2>
                <div class="w-full justify-between items-center text-slate-500 font-normal text-sm">
                    <input type="email" name="newsleeter" id="newsletter" class="bg-[#F9F9F9] outline-none border-none w-3/4 h-10 rounded-s px-2" placeholder="Your email adress" />
                    <input type="submit" value="Subscribe" class="w-1/4 h-10 rounded-e text-white bg-gradient-to-b from-[#3db5e6] to-[#5d5fef]" />
                </div>
                <p class="text-slate-500 text-base font-light w-full text-left">* Will send you weekly updates for your better Team management.</p>
            </div>
        </div>
        <div
            class="w-full items-center justify-center flex relative before:absolute before:content-('') before:w-10/12 before:-translate-x-1/2 before:h-0.5 before:bg-gray-400 before:top-0 before:left-1/2"
        >
            <h1 class="max-w-[80%] py-6 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text">
                Unlock the full potential of your workforce
            </h1>
        </div>
    </footer>
</template>

<script>
export default {
    name: "NewFooter",
};
</script>

<style lang="scss" scoped></style>
