<template>
    <div class="w-64 h-64 lg:w-72 lg:h-72 rounded-lg bg-white p-5 lg:p-5 flex flex-col justify-start items-center">
        <div class="w-full flex flex-row justify-start items-center mb-4">
            <slot></slot>
        </div>
        <h1 class="text-lg text-slate-700 font-bold w-full text-left mb-4">{{ title }}</h1>
        <p class="text-xs text-left font-light text-slate-500 w-full">{{ text + " " }} <a :href="link" class="text-[#5D5FEF]">Learn More</a></p>
    </div>
</template>

<script>
export default {
    name: "InfoCard",
    props: ["title", "text", "link"],
};
</script>

<style lang="scss" scoped></style>
